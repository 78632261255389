import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CustomIcon from "./CustomIcon";
import { isMobile } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    pointerEvents: "auto",
    "&:hover": {
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    maxWidth: 540,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
}))(Tooltip);

export default function CustomizedTooltips({
  iconName,
  tooltip,
  style = {},
  iconColor = "#A7A7A8",
}) {
  const classes = useStyles();

  return (
    <HtmlTooltip
      className={classes.container}
      enterTouchDelay={0}
      leaveTouchDelay={2000}
      placement="right"
      disableFocusListener
      disableHoverListener={!!isMobile()}
      title={<React.Fragment>{tooltip}</React.Fragment>}
    >
      <IconButton style={style}>
        <CustomIcon
          name={iconName || "Help"}
          color={iconColor}
          style={{ width: 20, height: 20 }}
        />
      </IconButton>
    </HtmlTooltip>
  );
}
